export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },        
      {
        _name: 'CSidebarNavItem',
        name: 'Formulir Penebangan',
        to: '/penebangan',
        icon: 'cil-pencil',
      },       
      {
        _name: 'CSidebarNavItem',
        name: 'Dokumen',
        to: '/pengajuan',
        icon: 'cil-list',
      },             
      {
        _name: 'CSidebarNavItem',
        name: 'Informasi Pohon',
        to: '/informasi',
        icon: 'cil-folder-open',
      }, 

      {
        _name: 'CSidebarNavTitle',
        name: 'Master',
        _children: ['Master']
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Data Taman',
        to: '/taman',
        icon: 'cil-puzzle',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Data Pohon',
        to: '/pohon',
        icon: 'cil-globe-alt',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Data Artikel',
        to: '/artikel',
        icon: 'cil-file',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Data Foto',
        to: '/foto',
        icon: 'cil-image',
      }, 
      {
        _name: 'CSidebarNavItem',
        name: 'Data User',
        to: '/user',
        icon: 'cil-people',
      },

      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Laporan',
      //   route: '/laporan',
      //   icon: 'cil-layers',
      //   items: [
      //     {
      //       name: 'Laporan Pohon',
      //       to: '/laporanpohon',
      //       icon: 'cil-layers'
      //     },
      //     {
      //       name: 'Laporan Taman',
      //       to: '/laporantaman',
      //       icon: 'cil-layers'
      //     }         
      //   ]
      // },  

      {
        _name: 'CSidebarNavTitle',
        name: 'Laporan',
        _children: ['Laporan']
      },      
      {
        _name: 'CSidebarNavItem',
        name: 'Rekap Pohon Perjalan',
        to: '/laporanpohon',
        icon: 'cil-layers'
      },     
      {
        _name: 'CSidebarNavItem',
        name: 'Rekap Taman',
        to: '/laporantaman',
        icon: 'cil-layers'
      },

      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Setting',
      //   route: '/setting',
      //   icon: 'cil-settings',
      //   items: [
      //     {
      //       name: 'Profilku',
      //       to: '/profilku',
      //       icon: 'cil-user'
      //     },
      //     {
      //       name: 'Ganti Password',
      //       to: '/gantipassword',
      //       icon: 'cil-envelope-open'
      //     },
      //     {
      //       name: 'Logout',
      //       to: '/logout',
      //       icon: 'cil-lock-locked',
      //     }         
      //   ]
      // }, 
            
      {
        _name: 'CSidebarNavTitle',
        name: 'Setting',
        _children: ['Setting']
      },
      
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Profilku',
      //   to: '/profilku',
      //   icon: 'cil-user'
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Ganti Password',
        to: '/gantipassword',
        icon: 'cil-envelope-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'cil-lock-locked',
      },
    ]
  }
]