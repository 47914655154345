<template>
  <CHeader fixed with-subheader warning class=" bg-warning text-white">
    <CToggler
      in-header
      class="text-white ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none text-white"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none bg-warning text-white" to="/">
    Pohon
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3 text-white">
        <CHeaderNavLink  class="text-white" to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3 text-white">
        <CHeaderNavLink  class="text-white" to="/login">
          Logout
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2 text-white">
        <!-- <CHeaderNavLink  class="text-white">
          <CIcon name="cil-bell"/>
        </CHeaderNavLink> -->
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2 text-white">
        <!-- <CHeaderNavLink  class="text-white">
          <CIcon name="cil-list"/>
        </CHeaderNavLink> -->
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2 text-white">
        <!-- <CHeaderNavLink  class="text-white">
          <CIcon name="cil-user"/>
        </CHeaderNavLink> -->
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
    <CModal
      title="Transaksi Terakhir"
      size="lg"
      :show.sync="transaksiterakhir"
    >
      <table class="table" id="datatable2">
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Nama </th>
              <th>Email </th>
              <th>Country </th>
              <th>Current Assets </th>
              <th>Last Activity </th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  
    </CModal>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data() {
    return {
        labelhead: "dashboard",
        transaksiterakhir : false
      }
  },
  mounted(){
    if(this.$store.state.labelhead=='Pos'){
        this.$store.commit('setLabelhead', 'Dashboard');
    }    
    else{
      this.$store.commit('setLabelhead', 'Pos');
    }
    // alert(this.labelhead);
  },
}
</script>
